import { tns } from 'tiny-slider/src/tiny-slider';
(function () {

  const slider = document.querySelector('.set-jersey-sliders__container');

  if (!slider) return this;

  const tiny_slider = tns({
    autoHeight: 'auto',
    autoplay: true,
    autoplayButtonOutput: false,
    autoplayTimeout: 10000,
    container: slider,
    items: 1,
    controls: false,
    loop: true,
    // nav: false,
    slideBy: 'page',
    speed: 250,
  });

})();

(function () {

  const slider = document.querySelector('.jersey-on-frame');

  if (!slider) return this;

  const tiny_slider = tns({
    autoHeight: 'auto',
    container: slider,
    items: 4,
    edgePadding: 30,
    controls: false,
    loop: true,
    // nav: false,
    slideBy: 'page',
    speed: 250,
  });

})();
